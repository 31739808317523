const formatDatetime = (unix: string, dayAsString: boolean): string => {
  const datetime = new Date(unix).toLocaleString('fi-FI', {
    year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'
  })
  let returnable = datetime

  if (dayAsString) {
    const yesterdayUnix = new Date().setDate(new Date().getDate() - 1)
    const givenDate = new Date(unix).toLocaleString('fi-FI', { year: 'numeric', month: 'numeric', day: 'numeric' })
    const dateNow = new Date().toLocaleString('fi-FI', { year: 'numeric', month: 'numeric', day: 'numeric' })
    const dateYesterday = new Date(yesterdayUnix).toLocaleString('fi-FI', { year: 'numeric', month: 'numeric', day: 'numeric' })

    if (givenDate === dateNow) {
      returnable = datetime.replace(givenDate, 'Tänään')
    }
    else if (givenDate === dateYesterday) {
      returnable = datetime.replace(givenDate, 'Eilen')
    }
  }

  return returnable
}

const formatDate = (unix: string, monthAsString?: boolean): string => {
  const monthFormat = monthAsString ? 'long' : 'numeric'
  return new Date(unix).toLocaleString('fi-FI', {
    year: 'numeric', month: monthFormat, day: 'numeric'
  })
}

export { formatDate, formatDatetime }
